import qs from 'qs';
import { LoginCheck, usingWxLogin } from '@/utils/general';
import { Toast } from 'antd-mobile';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const version = 'v1';

let isLogining = false;

export function request({ api, method, data }: RequestOption) {
  let url = `${BASE_URL}/${version}/${api}`;
  const cfg: any = {
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: usingWxLogin() ? localStorage.getItem('wxtoken') : localStorage.getItem('qytoken'),
    },
    method,
  };
  if (data) {
    if (method !== 'GET' && method !== 'DELETE') {
      cfg.body = JSON.stringify(data);
    } else {
      url += `?${qs.stringify(data)}`;
    }
  }
  return fetch(url, cfg)
    .then((response) => {
      if (!response.ok) {
        throw Error('Network response was not OK');
      }
      return response.json();
    })
    .then((res) => {
      if (res.code === 401) {
        usingWxLogin() ? localStorage.setItem('wxtoken', '') : localStorage.setItem('qytoken', '');
        if (!isLogining) {
          isLogining = true;
          LoginCheck(() => {
            isLogining = false;
          });
        }
      } else if (res.code !== 0) {
        Toast.show({
          content: res.message,
        });
      }
      return Promise.resolve(res);
    });
}

export function get(api: string, data?: any) {
  return request({
    method: 'GET',
    api,
    data,
  });
}
export function post(api: string, data?: any) {
  return request({
    method: 'POST',
    api,
    data,
  });
}
export function put(api: string, data?: any) {
  return request({
    method: 'PUT',
    api,
    data,
  });
}
export function del(api: string, data?: any) {
  return request({
    method: 'DELETE',
    api,
    data,
  });
}
interface RequestOption {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  api: string;
  data?: any;
}
