import React, { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { SafeArea } from 'antd-mobile';
import routes from './routes';
import { LoginCheck } from '@/utils/general';
import '@/assets/fonts/iconfont';
import './App.less';
// import VConsole from 'vconsole';

// const vConsole = new VConsole();
function App() {
  const element = useRoutes(routes);

  useEffect(() => {
    LoginCheck();
  });

  return (
    <div className="App">
      <div style={{ background: 'blue' }}>
        <SafeArea position="top" />
      </div>
      <div className="content">{element}</div>
      <div style={{ background: 'yellow' }}>
        <SafeArea position="bottom" />
      </div>
    </div>
  );
}

export default App;
