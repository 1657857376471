import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  window.h5Env !== 'browser' || process.env.REACT_APP_ENV === 'development' ? (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  ) : (
    <div>
      请在微信或企业微信中访问{window.h5Env}
      {process.env.REACT_APP_ENV}
    </div>
  ),
);
