import React from 'react';
import loadable from '@react-loadable/revised';

const loadingComponent = () => {
  return <div>loading...</div>;
};

export default (loader: any, loading = loadingComponent) => {
  return loadable({
    loader,
    loading,
  });
};
