import qs from 'qs';
import { login, wxLogin } from '@/api/common';

export const PAGE_SIZE = 6; // 分页条数

export const LoginCheck = (cb?: () => void) => {
  const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  if (window.location.search) {
    // 微信登录
    if (usingWxLogin()) {
      if (params.code) {
        const token = localStorage.getItem('wxtoken');
        localStorage.setItem('wxcode', params.code as string);
        if (!token) {
          wxLogin(params.code as string).then((res) => {
            if (res.code === 0) {
              localStorage.setItem('wxtoken', res.data.token);
            }
          });
        }
      }
      return;
    }

    // 企业微信登录
    if (params.code || localStorage.getItem('qycode')) {
      const code = params.code || localStorage.getItem('qycode');
      const token = localStorage.getItem('qytoken');
      params.code && localStorage.setItem('qycode', params.code as string);
      if (!token) {
        // 登录
        login(code as string).then((res) => {
          if (res.code === 0) {
            localStorage.setItem('qytoken', res.data.token);
            localStorage.setItem('qyuser', JSON.stringify(res.data));
            cb?.();
          }
        });
      }
    }
  }
};

// 是否走微信登录
export const usingWxLogin = () => {
  const path = window.location.pathname.toLocaleLowerCase();
  return path.startsWith('/wx');
};

// 加载生成二维码的文件
const loadScript = () => {
  return new Promise((resolve) => {
    const scriptEl = document.createElement('script');
    scriptEl.type = 'text/javascript';
    scriptEl.src = '/libs/qrcode.min.js';
    scriptEl.onload = (res) => {
      resolve(res);
    };
    document.body.appendChild(scriptEl);
  });
};
// 生成二维码
export const generateCode = (str: string, dom: HTMLSpanElement | HTMLDivElement | Element, instance?: any) => {
  return new Promise((resolve, reject) => {
    if (!str || !dom) {
      reject(new Error('no str or dom'));
    }
    if (!window.QRCode) {
      loadScript().then(() => {
        resolve(generateCode(str, dom, instance));
      });
    } else {
      let res = instance;
      if (res) {
        res.clear();
        res.makeCode(str);
      } else {
        res = new window.QRCode(dom, {
          text: str,
          width: 239,
          height: 239,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: window.QRCode.CorrectLevel.H,
        });
      }
      resolve(res);
    }
  });
};
